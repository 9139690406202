body {
  margin: 0;
  overflow-x: hidden;
}

#page {
  font-family: "Noto Sans JP", sans-serif;
}

.full-page {
  height: 100vh;
  width: 100vw;
}

.wide-page {
  position: relative;
  width: 100vw;
}

.section-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#particles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  z-index: -99;
}

#particles2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  z-index: -1;
}

#top-container {
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  flex-direction: column;
  z-index: 99;
  height: 100%;
}

#top nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#top nav a {
  font-size: 18px;
  text-decoration: none;
  color: gray;
  margin: 5px;
}

#top nav a:hover {
  cursor: pointer;
}

.buttonBox {
  position: relative;
}

.border {
  position: absolute;
  background: none;
  transition: all 0.5s ease-in-out;
  margin-left: 5px;
  margin-right: 5px;
  bottom: -2.5px;
  left: 0;
  width: 0;
  border-bottom: 3px solid #ff5b79;
  transition: width 0.3s ease-in-out;
}

#first:hover .border {
  width: calc(100% - 10px);
  transition: width 0.6s ease-in-out;
}

#second:hover .border {
  width: calc(100% - 10px);
  transition: width 0.6s ease-in-out;
}

#third:hover .border {
  width: calc(100% - 10px);
  transition: width 0.6s ease-in-out;
}

.section-element {
  margin: 10px;
}

#title-text {
  color: #ff5b79;
  font-size: 45px;
  border-right: solid 16px;
  padding-right: 16px;
  font-family: "IBM Plex Sans", sans-serif;
}

#illust {
  color: white;
  position: relative;
  overflow: hidden;
}

#illust::before {
  content: "";
  top: -16px;
  left: -16px;
  right: -16px;
  bottom: -16px;
  background: url("/assets/illust_mini.jpg") no-repeat center;
  background-size: cover;
  position: absolute;
  filter: blur(16px) brightness(0.75);
  z-index: -100;
}

#image {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.transparent {
  opacity: 0;
}

.fade {
  animation-name: fade;
  animation-duration: 0.7s;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#image:hover {
  cursor: pointer;
}

#illustrators {
  padding-top: 100px;
  padding-bottom: 100px;
}

#illustrator-title {
  color: gray;
  border-bottom: solid #ff5b79 4px;
}

.description {
  color: gray;
}

#illustrators-list {
  max-width: 1000px;
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  margin-top: 50px;
  justify-content: center;
}

.pane {
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 20px;
}

.illust {
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 100px;
  background: #ff92a650;
}

.illust img {
  width: 100%;
  height: 100%;
  display: block;
  transition-duration: 300ms;
}

.illust img:hover {
  cursor: pointer;
  transform: scale(1.2);
  transition-duration: 300ms;
}

.illustrator {
  margin-top: 4px;
  height: 36px;
  display: flex;
  align-items: center;
  background: #ff92a6;
  border-radius: 18px;
}

.illustrator-name {
  margin-left: 18px;
  color: #ffffff;
  flex-grow: 1;
  font-size: 14px;
}

.twitter-link {
  height: 32px;
  width: 32px;
  margin-right: 18px;
}

.twitter-link svg {
  height: 100%;
  width: 100%;
}

footer {
  padding: 30px;
  background: #ff92a6;
  color: white;
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

footer span {
  margin: 10px;
  max-width: 380px;
}

.float {
  position: fixed;
  padding: 8px;
  margin: 5px;
  border-radius: 5px;
  font-size: 16px;
  bottom: 10px;
  right: 10px;
  text-align: center;
  text-decoration: none;
  color: #ff5b79;
  background: rgba(255, 255, 255, 0.75);
}
